.bg-img {
  background-image: url("/src/assets/images/bg.jpg");
  background-size: cover;
  background-position: 26%;
  background-repeat: no-repeat;
}

.font-san {
  font-family: "Open Sans";
}

.bg-slate-800-70 {
  --tw-bg-opacity: 0.7;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-600-30 {
  --tw-bg-opacity: 0.3;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.hiddenScrollBar::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  height: 12px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #94a3b8;
  border-radius: 8px;
}
